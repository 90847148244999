import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/dfua22/dfua22/src/templates/Markdown.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "https://drive.google.com/drive/folders/1rVqfpoGs4I_4ZwagEj9osnr1LoGfYCQm?usp=sharing"
      }}>{`Get visual assets`}</a>{`  `}</p>
    <br />
    <p>{`Every day, millions of Ukrainians show incredible courage and strength resisting Russian aggression. Volunteers, individuals and organizations are working together to provide support and raise funds for those in need.`}</p>
    <br />
    <p>{`In times like these, uniting efforts and working together as a community matters more than ever. The DevFest Ukraine team returns for an important mission: to organize a conference with world-class technical speakers and raise funds for Ukraine.  `}</p>
    <br />
    <p><a parentName="p" {...{
        "href": "https://devfest.gdg.org.ua/"
      }}>{`DevFest for Ukraine`}</a>{` is a charitable tech conference that will bring together 20 industry-leading speakers over two days, featuring live streams from London and Lviv. It will address key topics for the future of tech, including trends in Android, Web, and AI.`}</p>
    <br />
    <p>{`Our initial goal was to raise $50,000. Now thanks to multiple donations from individuals and partners, we’ve reached our $50,000 and $75,000 milestones, and are targeting to raise $100,000. With these funds, we can buy about 1000 first aid kits, or 320 bulletproof vests, or 60 tons of humanitarian aid, which can save the lives of thousands of Ukrainians.`}</p>
    <h2>{`What can you expect at DevFest For Ukraine?`}</h2>
    <p>{`The conference will take place online on June 14 and 15, featuring both live and recorded sessions from London and Lviv, along with live Q&As. From tech sessions and inspirational keynotes to networking and overviews of the latest developer tools, DevFest for Ukraine brings together people who shape the future of Android, Web, and AI technologies. `}</p>
    <br /> 
    <p>{`Learn from industry-leading speakers like Romain Guy and Chet Haase who have been building Android since 1.0, Jhey Tompkins and Una Kravets from the Google Chrome team, or Robert Crowe from the TensorFlow team who will talk about trends in ML development.   The participation is donation-based: your donation will give you access to the live stream and recordings after the event. `}</p>
    <br />
    <p>{`The participants will also get an opportunity to interact with about 1,000 fellow developers from around the world, and most importantly — support an important cause: help Ukraine and Ukrainians stand against Russian aggression.`}</p>
    <h2>{`Where will donations go`}</h2>
    <p>{`All funds raised will go to three Ukrainian non-governmental organizations: "Come Back Alive", "Nova Ukraine" and "Children's Voices".  Each of them support a different cause in a transparent public way. Learn `}<a parentName="p" {...{
        "href": "https://devfest.gdg.org.ua/why-these-organizations/"
      }}>{`why we chose these organizations`}</a>{`.`}</p>
    <h2>{`How to join`}</h2>
    <p>{`Join the conference to support Ukrainians and gain knowledge from the speakers who are shaping the future of the Web, Android, and AI!`}</p>
    <br />
    <p>{`Join the fundraiser on the website to access live streams and session recordings: `}<a parentName="p" {...{
        "href": "https://devfest.gdg.org.ua/"
      }}>{`devfest.gdg.org.ua`}</a></p>
    <hr></hr>
    <p>{`Big thanks to our partners who #StandWithUkraine — `}<a parentName="p" {...{
        "href": "https://cloud.google.com/"
      }}>{`Google Cloud`}</a>{`, `}<a parentName="p" {...{
        "href": "https://developers.google.com/community"
      }}>{`Google Developers`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.outreach.io/"
      }}>{`Outreach`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.avenga.com/"
      }}>{`Avenga`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.wix.com/"
      }}>{`Wix`}</a>{` and `}<a parentName="p" {...{
        "href": "https://lemon.io/"
      }}>{`Lemon.io`}</a>{`. These great organizations donated above and beyond to help us reach our donation goals.`}</p>
    <br />
    <p>{`#DevFestForUkraine #StandWithUkraine`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      